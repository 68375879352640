.connexion {
	background-color: var(--bleugadisat);
}
.connexion.dashboard {
	background-color: var(--grisclair);
	display: flex;
}

.connexion main {
	display: flex;
	flex-direction: column;
	padding: 5rem 0;
}
.connexion main .container {
	padding: 0 2rem;
}
.connexion h1 {
	font-family: var(--medium);
	font-size: 2.5rem;
	text-transform: uppercase;
	margin-bottom: 0.5rem;
}
.connexion p {
	color: var(--blanc);
	font-size: 1.5rem;
}
.connexion .col50:first-of-type {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}
.connexion .col10:first-of-type {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}
.connexion .navAfter::after {
	background-color: var(--bleugadifonce);
	content: "";
	display: block;
	height: 1rem;
	margin-top: 2.8rem;
	width: 100%;
}
.colIntro {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	order: 2;
	margin-top: 3rem;
}
.colVideo {
	margin-top: 5rem;
}
.rowLabel {
	height: 0;
	width: 0;
}
.rowLabel label {
	direction: ltr;
	display: block;
	text-indent: -999em;
}
.rowForm {
	align-items: end;
}
.rowForm input {
	margin-bottom: 2rem;
}
.rowForm-create {
	display: flex;
	padding-top: 2rem;
	justify-content: end;
}
.linkVideo {
	align-items: center;
	color: var(--grisgadi);
	display: none;
	font-size: clamp(2rem, 2.4rem, 3rem);
	padding: 20px 0 0 0;
	text-transform: uppercase;
}
.linkVideo i {
	margin-left: 1rem;
}
.linkVideo i::before {
	font-size: 3rem;
}
@media screen and (max-width: 576px) {
}
@media screen and (min-width: 768px) {
	.rowForm {
		align-items: center;
		justify-content: space-between;
	}
	.rowForm input {
		margin-right: 2rem;
		margin-bottom: 0;
	}
	.rowForm input.formControlInstance{
		margin: 0;
	}
	.rowFormCreate {
		justify-content: end;
	}
	.rowInputs {
		padding-bottom: 10rem;
	}
	.rowLabel {
		height: 0px;
	}
	.rowTextuel {
		justify-content: space-between;
	}
	.rowTextuel .colMd40 {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		margin-right: 2rem;
	}
	.linkVideo {
		display: flex;
	}
	.connexion h1 {
		font-size: 4rem;
		margin: 0;
		width: 80%;
	}
	.connexion h1,
	.connexion p {
		width: 80%;
	}
	.connexion.dashboard p {
		width: unset;
	}
	.colIntro {
		order: unset;
		margin: 0;
		padding: 0;
	}
	.colVideo {
		margin: 0;
	}
	.btn.btnAccount {
		width: 26%;
	}
}
@media screen and (min-width: 992px) {
	.connexion .navAfter::after {
		height: 1.6rem;
		margin-top: 2.8rem;
	}
	.connexion main {
		padding: 6rem 0;
	}
}
@media screen and (min-width: 1200px) {
	.connexion h1 {
		font-size: 4rem;
		padding-bottom: 3rem;
	}
	.connexion h2 {
		color: var(--blanc);
		padding-bottom: 3rem;
	}
	.connexion.dashboard h2 {
		color: var(--bleugadisat);
		padding: 0;
	}
	.connexion p {
		padding-bottom: 2rem;
	}
	.connexion main {
		padding: 10rem 0;
	}
	.connexion.dashboard main {
		padding: unset;
		padding-top: 1rem;
	}
	.connexion header::before {
		height: 2.5rem;
	}
	body.connexion header::after {
		height: 1.2rem;
	}
	.connexion .container {
		width: 58%;
	}
	.connexion.dashboard .container {
		width: 100%;
	}
	.rowFormConnexion input,
	.rowFormConnexion .btnConnexion {
		width: 32%;
	}
	.navAfter .container {
		width: 84%;
	}
	.btnAccount:hover {
		color: var(--bleugris);
		background-color: var(--grisgadi);
	}
	.rowForm {
		align-items: center;
	}
	.linkVideo i::before {
		font-size: 3rem;
	}
	.btn.btnAccount {
		width: 32%;
	}
}
@media screen and (min-width: 1400px) {
}
@media screen and (min-width: 1800px) {
	.connexion header::before {
		height: 4rem;
	}
	.connexion header::after {
		height: 1.6rem;
	}
}
