p.infoFitarfou {
	color: var(--bleugadisat);
}

.labelMaintGfitarfou {
	display: flex;
	align-items: center;
}

.labelMaintGfitarfou:last-child {
	border-bottom: none; /* Pas de ligne de séparation après le dernier élément */
}
.labelMaintGfitarfou {
	width: 33.33%;
}
.modificationGfitarfou h2 {
	color: var(--bleugadisat);
	font-size: 2rem;
}
.modificationGfitarfou p {
	color: var(--bleugadisat);
	font-size: 1.3rem;
	margin: 1rem 0;
	padding: 0;
}
.highlightGfournisseur,
.highlightRef {
	color: var(--bleufond);
}
.panelMaintModuleSearch {
	align-items: center;
	justify-content: end;
	margin-bottom: 2rem;
}
.ModuleSearch {
	display: flex;
}
.ModuleSearch:hover {
	box-shadow: 0 0 4px var(--grisgadiHover);
}
.ModuleSearch:hover button {
	border: 1px solid var(--grisgadi);
}
.panelMaintModuleSearch input {
	width: auto;
}
.panelMaintModuleSearch input:hover {
	border-color: var(--grisgadi);
	border-right: none;
	box-shadow: none;
}
.panelMaintModuleSearch button {
	color: var(--blanc);
}
.panelMaintModuleSearch button:hover {
	border-color: var(--grisgadi);
	box-shadow: none;
}
.panelMaintModuleSearch .btnTelechargePdf {
	margin-right: 1rem;
}
.fileLabel {
	display: inline-block;
	margin-top: 1rem;
}
.choixMaintenanceModule {
	display: flex;
	border-bottom: 0.5rem solid var(--bleufond);
}
.choixMaintenanceModule button {
	width: 8%;
	border-bottom: 0;
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}
.choixMaintenanceModule button:not(.active) {
	background-color: var(--blanc);
	color: var(--bleufond);
	border: 1px solid var(--bleufond);
	border-left: 0;
	border-bottom: 0;
}
@media screen and (min-width: 768px) {
}
