.tabContentSwitchsAll .switchContainer:nth-child(odd) {
	background-color: transparent;
	border: none;
	color: var(--bleugadisat);
	margin: 0;
}
.tabContentSwitchs.tabContentSwitchsApp {
	display: unset;
}
.switchsDroitsApp {
	display: flex;
	gap: 1.5625rem;
}
.switchContainer {
	align-items: center;
	border-radius: 0.3125rem;
	display: flex;
	/* flex-direction: column; */
	gap: 2rem;
	margin-bottom: 0.625rem;
	padding: 1.25rem;
	border: 1px solid var(--grisD9);
}
.switchContainer:nth-child(odd) {
	background-color: var(--bleugadisat);
	border: 1px solid var(--bleugadisat);
	color: var(--blanc);
}
.switchContainer.switchContainerDble {
	flex-direction: row;
	gap: 2.5rem;
}
.switchContent {
	display: flex;
	flex-direction: column;
	align-items: center;
}
.switch {
	position: relative;
	display: inline-block;
	width: 50px;
	height: 24px;
}
label.switch:last-of-type {
	margin-bottom: 0;
}
.switch input {
	display: none;
}
.slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: var(--grisCc);
	transition: 0.4s;
	border-radius: 24px;
}
.slider:before {
	position: absolute;
	content: "";
	height: 18px;
	width: 18px;
	left: 3px;
	bottom: 3px;
	background-color: var(--blanc);
	transition: 0.4s;
	border-radius: 50%;
}
input:checked + .slider {
	background-color: var(--vert00B100);
}
input:checked + .slider:before {
	transform: translateX(26px);
}
.switchContainer.flexColumn label {
	margin: 0;
}
.switchCat {
	align-items: start;
	height: 100%;
	padding: 0;
}
@media screen and (max-width: 576px) {
}
@media screen and (min-width: 768px) {
}
@media screen and (min-width: 992px) {
}
@media screen and (min-width: 1200px) {
}
@media screen and (min-width: 1400px) {
}
@media screen and (min-width: 1800px) {
}
