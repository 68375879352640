.btnModule,
.btnTelecharge {
	margin-left: 1rem;
}
.btnOem {
	margin: 0;
	margin-bottom: 1.5rem;
}
.btnCreation,
.btnModification,
.btnSupprimer,
.btnInformation,
.btnTelecharge {
	width: 3.125rem;
	height: 3.125rem;
}
.btnModification {
	background-color: var(--jaunecoeur);
	margin-left: 0;
}
.btnSupprimer {
	background-color: var(--rougepl);
}
.btnInformation {
	background-color: var(--bleutypo);
	margin-left: 0;
}
.btnTelecharge {
	background-color: var(--grisA0);
	align-items: center;
	display: flex;
	justify-content: center;
}
.btnTelecharge.btnTelechargePdf {
	background-color: var(--gris7e);
}
.btnTelecharge:hover {
	background-color: var(--gris6c);
}
.btnCreation i::before,
.btnModification i::before,
.btnSupprimer i::before,
.btnInformation i::before {
	font-size: 1.5rem;
	align-items: center;
	justify-content: center;
	display: flex;
}
.btnModification:hover,
.btnJaune:hover {
	background-color: var(--jauneE6AD00);
}
.btnSupprimer:hover,
.btnRouge:hover {
	background-color: var(--rougepl);
}
.btnInformation:hover,
.btnBleu:hover {
	background-color: var(--bleufondhover);
}
.btnJaune {
	background-color: var(--jaunecoeur);
}
