header {
	background-color: var(--bleugadisat);
}
.dashboard header {
	background-color: var(--blanc);
	width: 18%;
	position: relative;
	padding-top: 1rem;
}
.dashboard header a.logoHeader{
	height: 70px;
	padding: 1rem 0;
	display: flex;
	align-items: center;
}
header.navAfter{
	padding-top: 2rem;
}
.topHeader{
	background-color: var(--bleufond);
	height: 2.5rem;
}
.topHeader .container{
	align-items: center;
	display: flex;
	height: inherit;
	justify-content: space-between;

}
.topHeader h1,
.topHeader p{
	color: var(--blanc);
	font-size: 1.33311rem;
	line-height: .85;
	font-family: var(--light);
}
.topHeader img{
	height: auto;
	width: 1.75rem;
}
.headerHeader {
	background-color: var(--bleugadisat);
	position: relative;
	z-index: 1;
}
.headerNosMarques {
	display: flex;
	align-items: center;
}
.headerPanel {
	position: relative;
}
.headerPanel .inputGroup {
	position: relative;
	display: inline-flex;
}
.headerPlusConsulter {
	display: flex;
	align-items: center;
}
.headerSelectionMoment {
	display: flex;
	align-items: center;
}
.headerToutesFamille {
	display: flex;
	align-items: center;
}
.headerSitesPartenaires {
	display: flex;
	align-items: center;
}
.inputGroup {
	margin: 1rem;
	/* width: 35%; */
	display: flex;
	align-items: center;
	border-radius: 4px 0 0 4px;
	overflow: hidden;
}
.headerPanel .inputGroup {
	margin: 0;
	margin-top: 1.5rem;
	position: relative;
	display: inline-flex;
}

.monPanier{
	background-color: var(--bleugadisat);
	color: var(--blanc);
	position: absolute;
	font-size: 5rem;
	display: flex;
}

.searchPanierContainer {
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: relative;
}


@media screen and (max-width : 576px){}
@media screen and (min-width : 768px){}
@media screen and (max-width: 899px) {
	/* .mon-panier{
		background-color: transparent;
    color: var(--bleufond);
		font-size: 3rem;
		margin: 0;
	} */
	 .topHeader .container{
		padding: 0 2rem;
	 }
}
@media screen and (min-width : 992px){
	.headerHeader {
		padding-top: 2rem;
	}
}
@media screen and (min-width : 1200px){}
@media screen and (min-width : 1400px){}
@media screen and (min-width : 1800px){}