.mainDashboard {
	width: 100%;
}
.globalContainer {
	display: flex;
	width: 100%;
}
.rechercheMeta {
	background-color: var(--grisD9);
	display: flex;
	padding: 1rem;
	padding-left: 1.81rem;
	justify-content: space-between;
}
.menuDashboard {
	background-color: var(--bleugadisat);
	height: 100%;
}
.listeItemsMenu {
	display: flex;
	align-items: start;
	flex-direction: column;
	padding: 1.81rem 0;
}
.listeItemsMenuSub {
	display: flex;
	align-items: start;
	flex-direction: column;
}
.itemMenu {
	color: var(--blanc);
	list-style: none;
	width: 100%;
}
.itemMenu i {
	margin-right: 2rem;
	font-size: 1.875rem;
	display: block;
}
.menuDashboard a {
	padding: 1rem 3.75rem;
	width: inherit;
	display: flex;
	color: var(--blanc);
}
.menuDashboard a {
	position: relative;
}
.menuDashboard a.appActive {
	background-color: var(--bleumenuactive);
}
.menuDashboard a.appActive::after {
	content: "";
	width: 0;
	height: 0;
	position: absolute;
	border-style: solid;
	border-top: 22px solid transparent;
	border-bottom: 22px solid transparent;
	border-right: 26px solid var(--bleugadisat);
	border-left: 0;
	top: 0;
	right: 0;
}
.menuDashboard a.subLinkItem {
	background-color: var(--bleusubmenu);
	color: var(--bleusubmenuitem);
}
.menuDashboard a.subLinkItem::after {
	display: none;
}
.menuDashboard a.subLinkItem.subAppActive::before {
	content: "";
	background-color: var(--bleufond);
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 1.25rem;
}

.menuDashboard a.subAppActive {
	color: var(--blanc);
}
.inputGroupRef {
	width: 30%;
	display: flex;
	align-items: center;
	overflow: hidden;
}
.btnRecherche {
	background-color: var(--bleufond);
	border: none;
	padding: 1rem;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 10%;
}
.btnRecherche i {
	color: white;
}
input.formControl::placeholder {
	color: var(--grisgadi);
	font-style: italic;
}
.appInfoLogin {
	display: flex;
	justify-content: end;
	align-items: center;
	min-width: 50%;
}
.appInfoLogin h2 {
	font-size: 1.5rem;
}
.appInfoLogin i {
	margin: 0 1rem;
}
.appInfoLogin h2.societe {
	font-family: var(--thin);
}
.deconnexion {
	cursor: pointer;
}

.mainContainer {
	padding: 1.81rem;
}
.panelChiffresCles {
	background-color: var(--blanc);
	border-radius: 0.3125rem;
	width: 100%;
	padding: 2rem;
}
.panelFavoris {
	padding: 2rem;
	width: 100%;
}
.panelChiffresCles .chiffresCles,
.favoris,
.module {
	font-family: var(--regular);
	padding-bottom: 3.5rem;
	font-size: 2rem;
}
.panelChiffresCles i,
.favoris i,
.module i {
	padding: 1.5rem;
}
.chiffresClesAdh {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.chiffresClesAdh .itemChiffresCles,
.chiffresClesAdh .augDimChiffres {
	color: var(--bleugadisat);
}
.titreChiffres {
	display: flex;
	align-items: center;
}
.titreChiffres .itemNombreCles {
	display: flex;
	align-items: center;
}
p.itemNombreCles {
	padding: 0;
}
.chiffresClesAdh .itemNombreCles {
	color: var(--grisgadi);
	font-family: var(--bold);
	font-size: 5rem;
}
.arrowGreen {
	color: var(--vert00B100);
}
.arrowRed {
	color: var(--rougepl);
}
.panelAjoutDate {
	background-color: var(--grisclair);
	border-radius: 5px;
	padding: 2rem;
	width: 16.3%;
	justify-content: space-between;
	position: relative;
}
.panelAjoutDate i {
	padding: 0;
	color: var(--bleugadisat);
}
.panelAjoutDate p {
	align-items: center;
	justify-content: space-between;
	color: var(--bleufond);
	font-family: var(--bold);
	padding: unset;
	position: relative;
}
.connexion.dashboard .panelAjoutDate p {
	width: unset;
	line-height: unset;
	height: unset;
	padding-bottom: 2rem;
}
.panelAjoutDate p::after {
	content: ""; /* Pas de contenu texte */
	display: block; /* Permet d'afficher la ligne */
	width: calc(100% + 4rem); /* Prend toute la largeur du parent */
	height: 2px; /* Épaisseur de la ligne */
	background-color: var(--grisprez); /* Couleur de la ligne */
	position: absolute; /* Positionnement relatif au conteneur */
	left: -2rem; /* Aligne la ligne avec le bord gauche du conteneur */
	bottom: 0;
}
.addDatesRow {
	justify-content: space-between;
	margin-top: 2rem;
}
.addRatePicker {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 48%;
}
.addDatePicker label {
	background-color: var(--grisclair);
	font-size: 1rem;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0 0.5rem;
	height: 2rem;
	position: relative;
	width: 61%;
	z-index: 1;
}
.addDatePicker.addDateFin label {
	width: 50%;
}
.addDatePicker input {
	color: var(--bleufond);
	background-color: transparent;
	border-radius: 2px;
	border: 1px solid var(--grisprez);
	font-size: 1rem;
	position: relative;
	margin-top: -1rem;
	padding: 0 0.5rem;
}
.addDatePicker:focus ~ label {
	background-color: var(--bleugadisat);
	color: var(--blanc);
}
.actions button:hover {
	background-color: #e0e0e0;
}
.ctoButtons {
	margin-top: 1rem;
	justify-content: end;
}
.ctoButtons button {
	background-color: transparent;
	align-items: end;
	color: var(--bleufond);
	font-size: 1.25rem;
	height: 3.5rem;
	padding: 0 1rem;
	border: none;
}
.connexion .ctoButtons button {
	width: auto;
}
.ctoButtons button:last-child {
	color: var(--bleugadisat);
	padding-right: 0;
}
.cardContainer {
	display: grid;
	grid-template-columns: repeat(4, 1fr); /* Toujours 4 colonnes */
	gap: 20px; /* Espacement entre les cartes */
}
.card {
	width: 100%;
	border-radius: 0.3125rem;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	padding: 1.875rem;
	position: relative;
	color: white;
	font-size: 18px;
	font-weight: bold;
	text-align: center;
	box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
	transition: all ease-in-out 0.5s;
}
.card.blue {
	background-color: var(--bleufond);
	cursor: pointer;
}
.card.dark-blue {
	background-color: var(--bleugadisat);
	cursor: pointer;
}
.card .icon {
	font-size: 4rem;
}

.card .favoriteEtoile {
	color: var(--vertetoile);
	font-size: 2rem;
}

.card .preFavoriteEtoile {
	color: var(--blanc);
	font-size: 2rem;
}
.card .favoriteCoeur {
	color: var(--jaunecoeur);
	font-size: 2rem;
}
.card .preFavoriteCoeur {
	color: var(--blanc);
	font-size: 2rem;
}
.card .nomModule {
	font-size: 1.875rem;
	text-align: center;
	margin-top: 3.75rem;
}
.card .nomModule span {
	display: block;
	font-size: 1.25rem;
	text-transform: uppercase;
	line-height: 2.4rem;
}
.card .colFavoris {
	width: 100%;
	justify-content: end;
	display: flex;
}
.card .colFavoris.favorisDouble {
	flex-direction: column;
	align-items: end;
	justify-content: unset;
}
.card .colFavoris.favorisDouble i:nth-child(2n) {
	margin-top: 1.2rem;
}
.card .colTitreModule {
	margin-top: 1.875rem;
	width: 100%;
}
.card.cardChoice {
	border: 2px dashed var(--bleugadisat);
}
.card.cardChoice * {
	color: var(--bleugadisat);
}
.card.card-on {
	flex-direction: row-reverse;
	transition: all ease-in-out 0.5s;
}
.card.card-on .colTitreModule {
	display: flex;
	align-items: center;
	justify-content: start;
}
.card.card-on .nomModule {
	margin: unset;
	margin-left: 1rem;
	text-align: left;
}
.card.card-on .colTitreModule {
	margin: unset;
}
.card.card-on .colFavoris {
	width: 5%;
}
.headerModule {
	display: flex;
	justify-content: space-between;
}
.buttonsChangeGrid {
	display: flex;
	justify-content: space-between;
}
.buttonsChangeGrid button {
	background-color: transparent;
	border: none;
	box-shadow: none;
	color: var(--grisD9);
	width: 40%;
	padding: 0;
	justify-content: unset;
}
.buttonsChangeGrid button:hover {
	border: none;
	box-shadow: none;
}
.buttonsChangeGrid button.grid-on {
	color: var(--bleugadisat);
}
.buttonsChangeGrid button:first-child {
	margin-right: 1.875rem;
}
.panelModule {
	margin-top: 1.875rem;
}

.colTitreModule i{
	font-size: 2.8rem;

}
@media screen and (max-width: 576px) {
}
@media screen and (min-width: 768px) {
	.titreChiffres {
		width: 20%;
	}
	.panelAjoutDate {
		width: 16.3%;
	}
}
@media screen and (min-width: 992px) {
}
@media screen and (min-width: 1200px) {
	.searchButton.searchHeader {
		width: 6rem;
	}
	.panelAjoutDate {
		width: 25%;
	}
}
@media screen and (min-width: 1400px) {
}
@media screen and (min-width: 1800px) {
	.panelAjoutDate {
		width: 16.3%;
	}
}
