.panelSelection {
	background-color: var(--blanc);
	border-radius: 0.3125rem;
	width: 100%;
	padding: 2rem;
	margin-bottom: 1.625rem;
}
/* .formGroup.libelle.fournisseur{
  width: 23.90%;
} */
.inputModal.fournisseur {
	width: 25%;
}
.btnModalValider.selectFou {
	margin-left: 1rem;
}
.inputFouValider {
	align-items: center;
	justify-content: space-between;
}
.labelOeil {
	justify-content: space-between;
}
.labelOeil i::before {
	font-size: 2rem;
}
/* Transition pour un effet ease-in-out */
.easeTransitionEnterActive,
.easeTransitionLeaveActive {
	transition: all 0.3s ease-in-out;
}

.easeTransitionEnterFrom,
.easeTransitionLeaveTo {
	opacity: 0;
	transform: translateY(-10px);
}

.easeTransitionEnterTo,
.easeTransitionLeaveFrom {
	opacity: 1;
	transform: translateY(0);
}
.panelSelection h3 {
	font-size: 1.8rem;
	text-transform: uppercase;
}
.panelSelection h3 span {
	text-transform: initial;
}
.formGroup {
	margin-top: 2rem;
}
/* MAINTENANCE GFITARFOU */
.formGroupMaintGfitarfou .inputFouValider {
	align-items: end;
	gap: 1.5rem;
	justify-content: start;
}
.blockInputModal {
	min-width: 20%;
}
.formGroupMaintGfitarfou input {
	width: 100%;
	/* border-radius: 0.3125rem 0 0 0.3125rem; */
}
.inputFouValider.liste {
	position: relative;
	top: -1px;
	width: 20%;
	z-index: 1;
}
.inputFouValider .inputModal:focus,
.inputFouValider .inputModal:visited {
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;
}
.autocompleteList {
	list-style: none;
	margin: 0;
	padding: 1.5rem 0;
	background-color: #fff;
	border: 1px solid #ccc;
	/* border-top: none; */
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
	max-height: 200px;
	overflow-y: auto;
	box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
	width: 100%;
}
.autocompleteItem {
	padding: 10px 15px;
	cursor: pointer;
	transition: background-color 0.3s ease;
	font-size: 1.2rem;
}
.autocomplete-item:hover {
	background-color: #f0f0f0;
}
.autocomplete-item:active {
	background-color: #d9d9d9;
}
.inputModalContainer {
	position: relative;
}
.inputFouValider i.croix {
	width: 5rem;
	height: 5rem;
	display: flex;
	background-color: transparent;
	color: var(--rougepl);
	justify-content: center;
	align-items: center;
	border-radius: 0 0.3125rem 0.3125rem 0;
	cursor: pointer;
	position: absolute;
	right: 1px;
	top: 1px;
}

.inputFouValider i.croix:hover {
	color: var(--rougehoverpl);
}
