/* FORMULAIRES */
input,
button,
.dropdownSelectedGMaint,
textarea {
	background: var(--blanc);
	font-size: 1.5rem;
	border: 1px solid var(--grisD9);
	border-radius: 0.3125rem;
	color: var(--bleutypofonce);
	display: flex;
	align-items: center;
	padding: 0 0.625rem;
	height: 5rem;
	margin: 0;
	transition: border-color 0.3s, box-shadow 0.3s, color 0.3s;
	width: 100%;
	font-family: var(--regular);
}
input:hover,
input:focus,
input:focus-visible,
button:hover,
.btn:hover,
.dropdownSelectedGMaint:hover,
.dropdownSelectedGMaint:focus {
	border-color: var(--bleufond);
	box-shadow: 0 0 4px rgba(52, 152, 219, 0.4);
	color: var(--bleufond);
	cursor: pointer;
}
input:disabled {
	border: 1px solid var(--grisD9);
	box-shadow: none;
	color: var(--gris6c);
}
.dropdownSelectedGMaint {
	border-right: none;
	border-top-right-radius: 0px;
	border-bottom-right-radius: 0px;
}
button:hover {
	color: var(--blanc);
}
button.searchHeader {
	background-color: var(--bleufond);
	color: var(--blanc);
}
input[type="number"]::-webkit-inner-spin-button {
	-webkit-appearance: none;
}
input[type="number"]::-moz-inner-spin-button {
	-moz-appearance: none;
}
::placeholder {
	color: var(--bleutypofonce);
	opacity: 1;
}
:focus-visible {
	outline: 1px;
	box-shadow: none;
}
label {
	color: var(--grisA0);
	display: block;
	margin-bottom: 1rem;
}
/* BOUTONS */
button {
	font-family: var(--bold);
	justify-content: center;
}
button i {
	font-size: 2rem;
	/* margin-right: 2rem; */
}
.btn {
	align-items: center;
	background-color: var(--bleufond);
	border: none;
	border-radius: 0.5rem;
	color: var(--blanc);
	cursor: pointer;
	display: flex;
	justify-content: center;
	padding: 0 1.5rem;
	/* width: 75%; */
}
.btn span {
	margin-left: 1.5rem;
	font-size: 1.5rem;
}
.formGroup .btn {
	margin: 0 1rem;
}
.formGroup .btn:first-of-type {
	margin-left: 0;
}
.formGroup .btn:last-of-type {
	margin-right: 0;
}
.btnOutline {
	background-color: transparent;
	border: 0.1rem solid #a9aeb6;
	color: #a9aeb6;
}
.btnPlain {
	color: #fff;
}
.btn2175FF {
	background-color: #2175ff;
}
.btn041D5B {
	background-color: var(--bleugadisat);
}
.searchButton {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
	height: 5rem;
	font-family: var(--medium);
	font-size: 1.6rem;
	margin-left: -1rem;
	width: 7rem;
}
.btnFamille {
	width: 100%;
}
.btnValider {
	margin: 0 auto;
	margin-top: 5rem;
	width: 25%;
}

@media screen and (max-width: 576px) {
	button i {
		font-size: 1.8rem;
	}
}
@media screen and (min-width: 768px) {
}
@media screen and (min-width: 992px) {
}
@media screen and (min-width: 1200px) {
	button {
		transition: all 0.3s ease-in-out;
	}
	button {
		width: unset;
	}
	.btn:hover {
		background: var(--bleufondhover);
		color: var(--blanc);
	}
}
@media screen and (min-width: 1400px) {
}
@media screen and (min-width: 1800px) {
}
