/* Conteneur principal */
.modalOverlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1000;
}

.modalContent {
	background: white;
	padding: 2rem;
	border-radius: 8px;
	width: 75%;
	position: relative;
	box-shadow: 0px 0.25rem 0.25rem 0px rgba(0, 0, 0, 0.1);
}
button.closeButton {
	color: var(--bleutypofonce);
	position: absolute;
	top: 10px;
	right: 10px;
	background: none;
	border: none;
	font-size: 1.125rem;
	cursor: pointer;
}
button.closeButton:hover {
	color: inherit;
	border: none;
	border-radius: 0;
	box-shadow: none;
}
/* Formulaire */
.texteEdition {
	margin-bottom: 1rem;
}
.formulaireModal {
	display: grid; /* Utilisation de CSS Grid */
	grid-template-columns: repeat(4, 1fr); /* 4 colonnes égales */
	gap: 3rem; /* Espacement entre les éléments */
	margin: 3rem 0;
	padding: 1rem;
	border: 1px solid #dfe4ea;
	border-radius: 8px;
	box-shadow: 0px 0px 0.25rem 0px rgba(0, 0, 0, 0.1);
}

.formulaireModalInfo {
	display: grid; /* Utilisation de CSS Grid */
	grid-template-columns: repeat(4, 1fr); /* 4 colonnes égales */
	gap: 1rem; /* Espacement entre les éléments */
	margin: 3rem 0;
	padding: 1rem;
	border: 1px solid #dfe4ea;
	border-radius: 8px;
	box-shadow: 0px 0px 0.25rem 0px rgba(0, 0, 0, 0.1);
}

/* Dropdown */
.dropdownGMaint,
.dropdownGfou {
	position: relative;
	cursor: pointer;
	width: 100%;
}

.dropdownSelectedGMaint,
.dropdownSelectedGfou {
	padding: 0.5rem;
	border: 1px solid var(--grisD9);
	background-color: var(--blanc);
	border-radius: 8px 0 0 8px;
}

.dropdownOptionsGMaint,
.dropdownOptionsGfou {
	position: absolute;
	top: 100%;
	left: 0;
	max-height: 200px; /* Limiter la hauteur visible */
	overflow-y: scroll !important; /* Forcer le défilement */
	overflow-x: hidden; /* Éviter le défilement horizontal */
	width: 100%; /* S'assurer que la liste prend toute la largeur du parent */
	background-color: white;
	border: 1px solid #ccc;
	z-index: 9999; /* S'assurer que la liste est visible */
}

.dropdownSelectedGMaint {
	border: 1px solid #ccc; /* Par défaut */
	border-right: 0px;
	border-bottom: 1px solid #ccc; /* Par défaut */
	border-radius: 0.3125rem; /* Coins arrondis */
	border-bottom-right-radius: 0;
	border-top-right-radius: 0;
	transition: border 0.2s ease; /* Transition fluide */
}

.dropdownSelectedGMaint.active {
	border-left: 1px solid #ccc;
	border-right: 1px solid #ccc;
	border-bottom: 0; /* Supprime la bordure du bas */
	border-radius: 0.3125rem 0; /* Coins arrondis en haut uniquement */
	border-bottom-right-radius: 0;
	border-top-right-radius: 0;
}

.dropdownOptionsGMaint li,
.dropdownOptionsGfou li {
	padding: 8px;
	cursor: pointer;
	font-size: 1.5rem;
}

.dropdownOptionsGMaint li:hover,
.dropdownOptionsGfou li:hover {
	background-color: var(--grisD9);
}

.dropdownGMaint.small,
.dropdownGfou.small {
	width: 100%; /* Assure que la largeur s'adapte au conteneur */
}
/* Grilles */
.formGrid {
	grid-template-columns: repeat(3, 1fr);
	padding: 1rem;
}
.formGrid:nth-child(2n) {
	background-color: var(--grisclair);
	border-radius: 0.5rem;
}
.formGrid.formGridSpecial {
	grid-template-columns: repeat(4, 1fr);
}
.gridCols2 {
	grid-template-columns: repeat(2, 1fr);
}
.gridCols4 {
	grid-template-columns: repeat(4, 1fr);
}
.formGrid,
.formGridSpecial,
.formGridSpecial .rightGroup,
.formGridUnite {
	display: grid;
	gap: 4rem;
}
.formGrid,
.formGridUnite {
	margin-bottom: 3rem;
}
/* .formGridUnite {
	display: flex;
	gap: 1rem;
	align-items: center;
} */
/* .formGridUnite .formGroupModal {
	width: 25%;
} */
.disable {
	color: var(--gris6c);
	background-color: var(--grisD9);
}
/* Boutons modale */
.modalFooter {
	display: flex;
	justify-content: end;
	padding-top: 2rem;
}
.modalFooter .btn {
	height: 5rem;
	margin: 0 2rem;
	justify-content: space-between;
}
.modalFooter .btn i {
	margin-right: 1rem;
}
.modalFooter .btn.btnModalAnnuler {
	background-color: var(--rougepl);
	margin: 0;
}
.modalFooter .btn.btnModalValider {
	background-color: var(--bleufond);
	margin-right: 0;
}
.btnModalAnnuler:hover {
	background-color: var(--rougehoverpl);
}
.panelMaintGfitarfouDropdown,
.panelMaintGfouDropdown {
	display: flex;
	position: relative; /* Conteneur parent pour positionner correctement la liste */
}
.panelMaintGfitarfouDropdown button,
.panelMaintGfouDropdown button {
	background-color: var(--bleufond);
	border-radius: 0 8px 8px 0;
	color: var(--blanc);
	width: 5rem;
}
.flecheDropdown i {
	transition: transform 0.3s ease; /* Transition pour une rotation fluide */
}

.fa-rotate-180 {
	transform: rotate(
		180deg
	); /* Rotation de 180 degrés pour orienter vers le haut */
}
.activeDesactive {
	z-index: -9999;
}
/* .switch-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 0.3125rem;
	padding: 0.9375rem;
} */
/* .switch {
	display: inline-block;
	height: 2.5rem;
	margin: 0;
	position: relative;
	width: 4.75rem;
}
.switch input {
	display: none;
}
.slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: var(--gris6c);
	transition: 0.4s;
	border-radius: 2.5rem;
}
.slider:before {
	position: absolute;
	content: "";
	height: 85%;
	width: 2rem;
	left: 0.1875rem;
	bottom: 0.13rem;
	background-color: var(--blanc);
	transition: 0.4s;
	border-radius: 50%;
}
input:checked + .slider {
	background-color: var(--vert00B100);
}
input:checked + .slider:before {
	transform: translateX(2.35rem);
} */

/* Tabs */
.tabs {
	display: grid;
	grid-template-columns: repeat(6, 1fr);
	grid-template-rows: repeat(1, 1fr);
	gap: 0;
}

button.tab {
	flex: 1;
	padding: 1rem;
	text-align: center;
	cursor: pointer;
	background-color: var(--blanc);
	filter: drop-shadow(0px 0px 0.25rem rgba(4, 29, 91, 0.2));
	border-bottom: none;
	color: var(--grisA0);
	font-family: var(--regular);
	font-size: 1.5rem;
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}
button.tab:hover {
	background-color: var(--blanc);
	color: var(--bleufond);
}
button.tab.active,
button.tab.active:hover {
	background-color: var(--bleugadisat);
	border: 1px solid var(--bleugadisat);
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
	color: var(--blanc);
	/* font-weight: bold; */
	filter: drop-shadow(0px 0px 0.25rem rgba(4, 29, 91, 0.2));
}

.tabContent {
	padding: 2rem;
	background-color: var(--blanc);
	border-top: 0.5rem solid var(--bleugadisat);
	border-radius: 0px 0px 0.3125rem 0.3125rem;
	box-shadow: 0px 0px 0.25rem 0px rgba(4, 29, 91, 0.2);
}
.dropZoneMedias {
	border: 0.25rem dashed var(--bleufond);
	border-radius: 1rem;
	padding: 1.5rem;
	color: var(--bleufond);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
.dropZoneMedias.display {
	margin: 2rem 0;
}
.dropZoneMedias p {
	font-size: 2.4rem;
	color: var(--bleufond);
}
.dropZoneMedias input,
.dropZoneMedias input:hover,
.dropZoneMedias input:focus,
.dropZoneMedias input:focus-visible {
	border: none;
	box-shadow: none;
	text-align: center;
}
.listUploadFile {
	list-style: none;
}
.listUploadFile li,
.fileLabel i {
	color: var(--bleufond);
	margin-bottom: 1rem;
}
.listUploadFile li i,
.fileLabel i {
	display: inline-block;
	font-size: 2.4rem;
	margin-right: 1rem;
}
.fileUpload {
	display: flex;
	flex-direction: column;
	align-items: center;
}
@media screen and (max-width: 576px) {
}
@media screen and (min-width: 768px) {
}
@media screen and (min-width: 992px) {
}
@media screen and (min-width: 1200px) {
}
@media screen and (min-width: 1400px) {
}
@media screen and (min-width: 1800px) {
}
