.panelMaintModule {
	background-color: var(--blanc);
	border-radius: 0.3125rem;
	width: 100%;
	padding: 2rem;
}
.labelMaintenanceModule {
	width: 33.33%;
}
.labelMaintenanceModuleBouton {
	display: flex;
}
.labelMaintenanceModule:last-child {
	border-bottom: none; /* Pas de ligne de séparation après le dernier élément */
}
.panelMaintModule .texteModule {
	font-size: 2rem;
}
.headerPanelMaintModule {
	align-items: center;
	display: flex;
	justify-content: space-between;
	padding-bottom: 2rem;
}
.panelMaintModuleTitle {
	align-items: center;
	display: flex;
}
.panelMaintModuleSearch {
	display: flex;
}
.panelMaintModuleSearch input {
	border: 1px solid var(--grisprez);
	border-right: 0px;
	border-bottom-right-radius: 0;
	border-top-right-radius: 0px;
}
.panelMaintModuleSearch input:hover,
.panelMaintModuleSearch input:focus {
	border: 1px solid var(--bleufond);
}
.panelMaintModuleSearch button {
	background-color: var(--grisgadi);
}
.enteteTableauGris {
	background-color: var(--grisclair);
	width: 100%;
	border-radius: 0.313rem;
}
.labelMaintenanceModule {
	display: flex;
	width: 9%;
	align-items: center;
}

.labelMaintenance {
	color: var(--bleugadisat);
	font-size: 1.5rem;
}
.infoMaintenanceModule {
	list-style-type: none;
	padding: 0;
	width: 100%;
	align-items: center;
	justify-content: center;
	padding: 0 4rem;
}
.infoMaintenanceModule li {
	display: flex;
	width: 100%;
	justify-content: space-between;
	padding: 2rem 0;
}
.infoMaintenanceModule label {
	margin: 0;
	padding: 0;
}
.infoMaintenanceModule label i {
	font-size: 1.25rem;
	padding: 0.25rem;
	color: var(--grisgadi);
}
.infoMaintenanceModule label i:hover,
.infoMaintenanceModule label i:focus {
	cursor: pointer;
	color: var(--gris6c);
}
.infoMaintenanceModule label i:last-of-type {
	margin-right: 0.5rem;
}
.infoTableauMaintenance {
	list-style-type: none;
	padding: 0;
	width: 100%;
	align-items: center;
	justify-content: center;
}
.infoTableauMaintenance li {
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;
	padding: 1rem 4rem;
}
.listeMaintenance > li {
	border-bottom: 0.2rem solid var(--grisclair);
}
.panelPage {
	/* background-color: var(--blanc);
	border: 1px solid var(--grisprez); */
	/* padding: 3rem; */
	margin: 2rem auto; /* Centre l'élément */
	/* border-radius: 5px; */
	width: 30%;
	padding: 1rem 0;
	justify-content: center;
	align-items: center;
	display: flex;
}
.panelPage .listPage li:first-child {
	margin: 0 4rem;
	/* z-index: -999; */
}
.panelPage .listPage li:last-child {
	margin: 0 4rem;
	/* z-index: -999; */
}
.panelPage .listPage li:nth-child(2) {
	padding-left: 0;
	margin-left: -1.5rem;
}
/* Cible l'avant-dernier élément et enlève 1.5rem à droite */
.panelPage .listPage li:nth-last-child(2) {
	padding-right: 0;
	margin-right: -1.5rem;
}
.itemPage {
	list-style: none;
	display: flex;
	align-items: center;
}
.itemPage.currentPage span {
	color: var(--grisgadi);
	margin: 0;
	margin-left: 0.5rem;
}
.itemPage span,
.itemPage i {
	margin: 0 1.5rem 0;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	color: var(--bleugadisat);
}
span.textItemNav {
	left: -9999px;
	position: absolute;
}
/* RÉSULTATS GFITARFOU */
.tabMaintModuleGfitarfou label,
.tabMaintModuleGfitarfou span,
.tabMaintModule label,
.tabMaintModule span {
	width: 12.5%;
}
.tabMaintModuleGfitarfou label:nth-child(2),
.tabMaintModuleGfitarfou span:nth-child(2),
.tabMaintModule label:nth-child(2),
.tabMaintModule span:nth-child(2) {
	width: 25%;
}
.tabMaintModuleGfitarfou label:nth-child(3),
.tabMaintModuleGfitarfou span:nth-child(3),
.tabMaintModule label:nth-child(3),
.tabMaintModule span:nth-child(3) {
	width: 8%;
}

.inputIcone {
	flex-direction: row;
	align-items: center;
	height: 5rem;
}
.inputIcone input {
	border-radius: 0.3125rem 0 0 0.3125rem;
}
.inputIcone i {
	width: 5rem;
	height: 100%;
	height: 5rem;
	display: flex;
	background-color: var(--bleufond);
	justify-content: center;
	align-items: center;
	border-radius: 0 0.3125rem 0.3125rem 0;
}
.inputIcone i::before {
	color: var(--blanc);
	font-size: 2rem;
}
@media screen and (min-width: 768px) {
	.labelMaintenanceModule {
		padding: 1rem;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
}
