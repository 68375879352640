.inputGroupInstance {
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
}

.formControlInstance {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-right: none;
  border-radius: 5px 0 0 5px;
}
.formControlInstance::placeholder {
  color: var(--grisgadi); /* Couleur par défaut du placeholder */
  font-style: italic;
}
.formControlInstance.placeholderSelected::placeholder {
  color: var(--bleugadisat); /* Couleur du placeholder une fois une sélection faite */
}
.formControlInstance.placeholderSelected {
  color: var(--bleugadisat); /* Change la couleur de l'input aussi si nécessaire */
}
/* Border radius when dropdown is open */
.formControlInstance.dropdown-open {
  border-radius: 5px 0 0 0; /* Changement lorsque le dropdown est ouvert */
}

.boutonInstance {
  background-color: var(--bleufond);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  border-radius: 0 5px 5px 0;
}
/* Icône par défaut */
.boutonInstance i {
  transition: transform 0.3s ease; /* Transition pour une rotation fluide */
}
/* Rotation de l'icône */
.boutonInstance i.rotated {
  transform: rotate(180deg); /* Faire pivoter de 180° */
}

.connexion button.boutonInstance{
  width: 5%;
}

.dropdownMenu {
  /* display: none; */
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 0 0 5px 5px;
  width: 95%;
  z-index: 1000;
}

.dropdownMenu li {
  padding: 10px;
  cursor: pointer;
}

.dropdownMenu li:hover {
  background-color: #f0f0f0;
}

.dropdownMenu[v-show="true"] {
  display: block;
}