.choixDroitsModules h3,
.GfitarfouOem h3 {
	color: var(--bleufond);
	font-size: 2rem;
	margin: 30px 0 20px 0;
}
.choixDroitsModules ul,
.GfitarfouOem ul {
	border: 2px solid var(--bleufond);
	border-right: 0;
	border-left: 0;
	padding: 1rem 0;
	list-style: none;
}
.choixDroitsModules ul li,
.GfitarfouOem ul li {
	color: var(--grisD9);
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-bottom: 1px solid var(--grisD9);
}
.choixDroitsModules ul li span,
.GfitarfouOem ul li span {
	font-size: 1.8rem;
}
.choixDroitsModules ul li button,
.choixDroitsModules ul li button:hover,
.GfitarfouOem ul li button,
.GfitarfouOem ul li button:hover {
	border: none;
	box-shadow: none;
	cursor: pointer;
}
.choixDroitsModules ul li button i,
.GfitarfouOem ul li button i {
	color: var(--grisD9);
	font-size: 2.8rem;
}
.choixDroitsModules ul li.active,
.GfitarfouOem ul li.active {
	color: var(--bleufond);
}
.choixDroitsModules ul li.active i{
	color: var(--vert00B100);
}

.GfitarfouOem ul li.active i{
	color: var(--rougepl);
}
.choixDroitsModules ul li:last-child,
.GfitarfouOem ul li:last-child {
	border: none;
}
@media screen and (max-width: 576px) {
}
@media screen and (min-width: 768px) {
}
@media screen and (min-width: 992px) {
}
@media screen and (min-width: 1200px) {
}
@media screen and (min-width: 1400px) {
}
@media screen and (min-width: 1800px) {
}
