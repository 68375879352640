/****************/
/* Normaliz CSS */
/****************/

* {
	margin: 0;
	padding: 0;
	border: 0;
	box-sizing: border-box;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
body {
	background-color: var(--grisclair);
	color: var(--bleugadisat);
	font-size: 1.6rem;
	font-family: var(--regular), sans-serif;
	font-weight: 400;
	line-height: 1;
	position: relative;
	overscroll-behavior-x: none;
}
html {
	font-size: 62.5%;
}
html,
body {
	overflow-x: hidden !important;
	width: 100%;
	margin: 0;
	padding: 0;
	height: 100%;
}
select {
	align-items: center;
	display: flex;
	border-radius: 0.5rem;
	background-color: var(--blanc);
	justify-content: center;
	height: 4rem;
	width: 100%;
}
a {
	font-style: normal;
	text-decoration: none;
}
input[readonly],
textarea[readonly] {
	/* background-color: #f0f0f0; */
	color: var(--bleugadisat);
	border: 1px solid #d0d0d0;
	cursor: not-allowed;
}
sup {
	color: var(--rougepl);
	display: inline-block;
	margin-top: -3px;
	position: relative;
}
@media screen and (max-width: 899px) {
	body.open {
		overflow-y: hidden !important;
	}
}
@media screen and (min-width: 768px) {
}
@media screen and (min-width: 992px) {
}
@media screen and (max-width: 1024px) {
	.revenirArriere {
		padding: 2rem;
		align-items: center;
	}
	.retour {
		padding-left: 1rem;
	}
}
@media screen and (min-width: 1200px) {
}
@media screen and (min-width: 1400px) {
}
@media screen and (min-width: 1800px) {
}
