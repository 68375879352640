/* .labelMaintenance-gfitarfou{
  display: flex;
  width: 13%;
  align-items: center;
} */
.formGroup.libelle {
	padding: 1rem 0;
}
.noData {
	text-align: center;
	margin: 1.25rem 0;
	font-size: 1.6rem;
	color: var(--gris6c);
	font-style: italic;
}
/* .info-maintenance-gfou {
	list-style-type: none;
	padding: 0;
	width: 100%;
	align-items: center;
	justify-content: center;
} */
.labelMaintenanceGfou {
	width: 33.33%;
}
.labelMaintenanceGfouBouton {
	display: flex;
}
.labelMaintenanceGfou:last-child {
	border-bottom: none; /* Pas de ligne de séparation après le dernier élément */
}
.labelMaintenanceGfou .fa-check {
	color: var(--vert00B100);
}

.labelMaintenanceGfou .fa-xmark {
	color: var(--rougepl);
}

.labelMaintenanceGfou .fa-star {
	color: var(--jaunecoeur);
}

@media screen and (min-width: 768px) {
	.label-modal-gfou {
		width: 10%;
	}
	/* .labelMaintenance-gfou-bouton{
    width: 10%;
  } */
}
@media screen and (max-width: 1024px) {
	/* .info-maintenance-gfou {
		width: 15%;
		padding: 2rem 4rem;
	} */
	.bouton-gfou {
		width: 10%;
	}
}
