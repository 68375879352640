/**********/
/* Colonnes */
/**********/

.col10 {
	width: 10%;
}
.col15 {
	width: 15%;
}
.col20 {
	width: 20%;
}
.col30 {
	width: 30%;
}
.col33 {
	width: 33.33%;
}
.col34 {
	width: 34%;
}
.col35 {
	width: 35%;
}
.col36 {
	width: 36%;
}
.col37 {
	width: 37%;
}
.col40 {
	width: 40%;
}
.col44 {
	width: 44.5%;
}
.col45 {
	width: 45%;
}
.col50 {
	width: 50%;
}
.col51 {
	width: 51%;
}
.col53 {
	width: 53%;
}
.col60 {
	width: 60%;
}
.col70 {
	width: 70%;
}
.col73 {
	width: 73%;
}
.col75 {
	width: 75%;
}
.col80 {
	width: 80%;
}
.col90 {
	width: 90%;
}
.col100 {
	width: 100%;
}
/*MISE EN PAGE*/
.dFlex {
	display: flex;
}
.dGrid {
	display: grid;
}
.dBlock {
	display: block;
}
.dNone {
	display: none;
}
.alignItemsCenter {
	align-items: center;
}
.justifyContentCenter {
	justify-content: center;
}
.justifyContentBetween {
	justify-content: space-between;
}
.flexColumn {
	flex-direction: column;
}
.flexRow {
	flex-direction: row;
}
.flexColumnReverse {
	flex-direction: column-reverse;
}
.flexRowReverse {
	flex-direction: row-reverse;
}
.row {
	display: flex;
	flex-direction: row;
	margin-left: -1.5rem;
	margin-right: -1.5rem;
}
.column {
	display: flex;
	flex-direction: column;
}
.container,
.xlContainer {
	margin: 0 auto;
	padding: 0 2rem;
	width: 100%;
}
@media screen and (max-width: 576px) {
	.container {
		width: 100%;
	}
}
@media screen and (min-width: 768px) {
	/* [class*="col-md-"] {
		padding: 0 2rem;
	} */
	[class*="colMd"] {
		padding: 0 0 2rem 0;
	}
	.colMd10 {
		width: 10%;
	}
	.colMd13 {
		width: 13%;
	}
	.colMd15 {
		width: 15%;
	}
	.colMd20 {
		width: 20%;
	}
	.colMd25 {
		width: 25%;
	}
	.colMd30 {
		width: 30%;
	}
	.colMd40 {
		width: 40%;
	}
	.colMd50 {
		width: 50%;
	}
	.colMd60 {
		width: 60%;
	}
	.colMd70 {
		width: 70%;
	}
	.colMd80 {
		width: 80%;
	}
	.colMd83 {
		width: 83%;
	}
	.colMd90 {
		width: 90%;
	}
	.colMd100 {
		padding: 0;
		width: 100%;
	}
	.mdRow {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		margin-left: -2rem;
		margin-right: -2rem;
	}
	.mdRowReverse {
		flex-direction: row-reverse;
	}
	.mdColumn {
		flex-direction: column;
	}
	.mdColumnReverse {
		flex-direction: column-reverse;
	}
	.col50 {
		width: calc(50% - 3rem);
	}
	.col50:first-of-type {
		margin: unset;
	}
	.container {
		width: 98%;
	}
	.dMdFlex {
		display: flex;
	}
}
@media screen and (max-width: 899px) {
	/* .container{
		padding: 0 2rem;
	} */
}
@media screen and (min-width: 992px) {
	.container {
		width: 98%;
	}
	.lgRow {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		margin-left: -2rem;
		margin-right: -2rem;
	}
}
@media screen and (min-width: 1200px) {
	[class*="colL"] {
		padding: 0 2rem;
	}
	.colL10 {
		width: 10%;
	}
	.colL20 {
		width: 20%;
	}
	.colL30 {
		width: 30%;
	}
	.colL40 {
		width: 40%;
	}
	.colL50 {
		width: 50%;
	}
	.colL60 {
		width: 60%;
	}
	.colL70 {
		width: 70%;
	}
	.colL80 {
		width: 80%;
	}
	.colL90 {
		width: 90%;
	}
	.colL100 {
		width: 100%;
	}
	.lRow {
		flex-direction: row;
	}
	.lRowReverse {
		flex-direction: row-reverse;
	}
	.lColumn {
		flex-direction: column;
	}
	.lColumnReverse {
		flex-direction: column-reverse;
	}
	.container {
		width: 114rem;
	}
}
@media screen and (min-width: 1400px) {
	.colXl10 {
		width: 10%;
	}
	.colXl20 {
		width: 20%;
	}
	.colXl30 {
		width: 30%;
	}
	.colXl40 {
		width: 40%;
	}
	.colXl50 {
		width: 50%;
	}
	.colXl60 {
		width: 60%;
	}
	.colXl70 {
		width: 70%;
	}
	.colXl80 {
		width: 80%;
	}
	.colXl90 {
		width: 90%;
	}
	.colXl100 {
		width: 100%;
	}
	.xlRow {
		flex-direction: row;
	}
	.xlRowReeRverse {
		flex-direction: row-reverse;
	}
	.xlColumn {
		flex-direction: column;
	}
	.xlColumnReverse {
		flex-direction: column-reverse;
	}
	.container {
		width: 135rem;
	}
}
@media screen and (min-width: 1800px) {
	.colXxl10 {
		width: 10%;
	}
	.colXxl20 {
		width: 20%;
	}
	.colXxl30 {
		width: 30%;
	}
	.colXxl40 {
		width: 40%;
	}
	.colXxl50 {
		width: 50%;
	}
	.colXxl60 {
		width: 60%;
	}
	.colXxl70 {
		width: 70%;
	}
	.colXxl80 {
		width: 80%;
	}
	.colXxl90 {
		width: 90%;
	}
	.colXxl100 {
		width: 100%;
	}
	.xxlRow {
		flex-direction: row;
	}
	.xxlRowReverse {
		flex-direction: row-reverse;
	}
	.xxlColumn {
		flex-direction: column;
	}
	.xxlColumnReverse {
		flex-direction: column-reverse;
	}
	.connexion .container {
		padding: 0;
	}
	.container,
	.connexion.dashboard .container  {
		padding: 0 1.875rem;
		width: 100%;
	}
	
}
